import React from "react"
import styles from "./details.module.scss"
import trigoImage from "../../../../../images/pasionaria/Trigo4.png"

function Detail({ title, firstLine, secondLine }) {
    return <div className={styles.detail}>
        <h4>
            {title}
        </h4>
        <p>
            {firstLine}
        </p>
        <p>
            {secondLine}
        </p>
    </div>
}

function MobileDetail({ title, firstLine, secondLine, thirdLine, width }) {
    return <div className={styles.mobileDetail} style={{ width }}>
        <h4>
            {title}
        </h4>
        <p>
            {firstLine}
        </p>
        <p>
            {secondLine}
        </p>
        <p>
            {thirdLine}
        </p>
    </div>
}

const Details = () => {

    return <section className={styles.detailsContainer}>
        <img src={trigoImage} alt={"trigo"} className={styles.mobileTrigoImage}/>
        <Detail title={"APARIENCIA:"}
                firstLine={"OPALESCENTE, DORADA Y TURBIA."}
                secondLine={"ESPUMA CREMOSA Y COMPACTA."}
        />
        <Detail title={"SABOR:"}
                firstLine={"FRUTAL. CON FINAL SEDOSO"}
                secondLine={"Y LIGERAMENTE DULCE."}
        />
        <Detail title={"AROMA:"}
                firstLine={"FRUTAS TROPICALES"}
                secondLine={"Y CÍTRICAS."}
        />
        <Detail title={"MARIDAJE IDEAL:"}
                firstLine={"EXCELENTE PARA ACOMPAÑAR ENSALADAS Y SANDWICHES"}
                secondLine={"AGRIDULCES COMO ASÍ TAMBIÉN POSTRES FRUTALES Y ÁCIDOS."}
        />
        <MobileDetail title={"SABOR:"}
                      firstLine={"FRUTAL."}
                      secondLine={"CON FINAL SEDOSO"}
                      thirdLine={"Y LIGERAMENTE DULCE."}
                      width={"28%"}
        />
        <MobileDetail title={"APARIENCIA:"}
                      firstLine={"OPALESCENTE,"}
                      secondLine={"DORADA Y TURBIA."}
                      thirdLine={"ESPUMA CREMOSA Y COMPACTA."}
                      width={"35%"}
        />
        <MobileDetail title={"AROMA:"}
                      firstLine={"FRUTAS TROPICALES"}
                      secondLine={"Y CÍTRICAS."}
                      width={"28%"}
        />
        <MobileDetail title={"MARIDAJE IDEAL:"}
                      firstLine={"EXCELENTE PARA ACOMPAÑAR ENSALADAS Y SANDWICHES"}
                      secondLine={"AGRIDULCES COMO ASÍ TAMBIÉN POSTRES FRUTALES Y ÁCIDOS."}
        />
    </section>
}

export default Details
