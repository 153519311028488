// import styles from "./footer.module.scss";

// import React from "react";

// const Footer = () => {

//   return (
//     <div className={styles.container}>
//       <p>CERVEZA PATAGONIA | BARILOCHE, RIO NEGRO, ARGENTINA</p>
//       <p>BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS.</p>
    
//     </div>
//   );
// };

// export default Footer;


import React from "react"
import PropTypes from "prop-types"


export default class Footer extends React.Component {
  static propTypes = {
    user: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    return (
      <div className={"footerWrapper"}>
        <div className={"footerTermsWrapper"}>
          <div className={"footerSocialInfo"}>
            <div>
            <a
                href={"https://www.cerveceriaymalteriaquilmes.com/"}
                className={"footerLink footerLinkText"}
              >
                INFORMACIÓN OFICIAL DE LA COMPAÑÍA
              </a>
            <span className={"footerLink footerLinkText footerLinkSeparator"}>
                |
              </span>
              <a
                href="/docs/TyCRecetasEspeciales.pdf"
                className={"footerLink footerLinkText"}
              >
                Términos y condiciones
              </a>
              <span className={"footerLink footerLinkText footerLinkSeparator"}>
                |
              </span>
              <a
                href="/docs/PolRecetasEspeciales.pdf"
                className={"footerLink footerLinkText"}
              >
                Políticas de privacidad
              </a>
             
              <p style={{margin: 0, fontSize: "12px", marginTop: "5px", fontWeight: "100"}}>TENÉS QUE SER MAYOR DE 18 AÑOS PARA ENTRAR AL SITIO. SI SOS MENOR DE EDAD TENÉS QUE SABER QUE EL CONSUMO DE ALCOHOL NO ES BUENO PARA TU SALUD.</p>
              <div className={"footerMessage"}>
                BEBER CON MODERACIÓN. PROHIBIDA SU VENTA A MENORES DE 18 AÑOS.
                NO COMPARTA EL CONTENIDO CON MENORES. ©2021 CERVEZA PATAGONIA
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
