import React from "react"
import styles from './pasionaria.module.scss'
import canImage from '../../../../images/pasionaria/Pasionaria-Lata.png'
import limitedEditionContainer from '../../../../images/pasionaria/yellowContainer.svg'
import specificationsImage from '../../../../images/pasionaria/specifications.png'
import malta from '../../../../images/pasionaria/malta.png'
import fruitImage from '../../../../images/pasionaria/Fruto.png'
import trigoImage from '../../../../images/pasionaria/Trigo4.png'
import flowerImage from '../../../../images/pasionaria/Flor.png'
import leafImage from '../../../../images/pasionaria/Hoja flor.png'
import CallToAction from "./CallToAction/CallToAction"


const Pasionaria = () => {
    return <section className={styles.sectionContainer}>
        <img src={fruitImage} alt={'fruto'} className={styles.fruitImage}/>
        <img src={trigoImage} alt={'trigo'} className={styles.trigoImage}/>
        <img src={flowerImage} alt={'flor'} className={styles.flowerImage}/>
        <div className={styles.container}>
            <div className={styles.mobileTitleContainer}>
                <div className={styles.mobileLimitedEditionContainer}>
                    <img src={limitedEditionContainer} alt={"container"}/>
                    <p>EDICIÓN LIMITADA</p>
                </div>
                <h2>PASIONARIA</h2>
                <CallToAction isMobile/>
            </div>
            <div className={styles.canImageContainer}>
                <img src={leafImage} alt={'hoja'} className={styles.mobileLeafImage}/>
                <img src={malta} alt={"malta"} className={styles.maltaImage}/>
                <img src={canImage} alt={"pasionaria"} className={styles.canImage}/>
            </div>
            <div className={styles.infoContainer}>
                <div>
                    <div className={styles.limitedEditionContainer}>
                        <img src={limitedEditionContainer} alt={"container"}/>
                        <p>EDICIÓN LIMITADA</p>
                    </div>
                    <h2>PASIONARIA</h2>
                    <CallToAction/>
                </div>
                <div className={styles.textContainer}>
                    <p>ENTRE COPAS DE INMENSOS ÁRBOLES, RÍOS Y CASCADAS NACE PASIONARIA:</p>
                    <div>
                        <p>UNA NEIPA CON MARACUYÁ FRUTAL.</p>
                        <p>PARA TOMAR EN CUALQUIER MOMENTO DEL AÑO.</p>
                    </div>
                    <div>
                        <p>UN VERDADERO ESCAPE PARA AVENTUREROS</p>
                        <p>QUE BUSCAN EN SU CERVEZA UN VIAJE DE SENTIDOS.</p>
                    </div>
                </div>
                <img src={specificationsImage} alt={"especificaciones"} className={styles.specificationsImage}/>
            </div>
        </div>
    </section>
}

export default Pasionaria;
