import React, { useEffect, useState } from "react"
import Recetas from "../components/recetasespeciales/recetas/recetas"
import Footer from "../components/recetasespeciales/footer/footer"
import SEO from "../components/seo"
import BirthConsent from "../components/BirthConsent"
import Cookies from "js-cookie"

const hasConfirmed = () => {
    return (Cookies.get('patagoniaBirthConsent') !== undefined && Cookies.get('patagoniaBirthConsent'))
      || (localStorage.getItem('patagoniaBirthConsent') !== undefined && localStorage.getItem('patagoniaBirthConsent'));
  };

const RecetasEspeciales = () => {

    const [age, setAge] = useState(false)
    const handleConsent = () =>{
        setAge(true)
    }

    useEffect(()=>{
        if(hasConfirmed())setAge(true)
      },[])

    useEffect(() => {
        window.location.href = "https://www.cervezapatagonia.com.ar/recetas-especiales/coco-lager";
    }, [])

    return (
        age ? (
            <>
            <SEO
                title={'Recetas Especiales'}
                description={'Recetas Especiales'}
            />
            {/*<Navbar/>*/}
            <Recetas/>
            {/*<EquipoCervecero/>*/}
            {/*<VideoReceta/>*/}
            {/*<FormReceta/>*/}
            {/*<DondeEncontrarla />*/}
            {/*<MapaReceta/>*/}
            <Footer />
        </>
        ):(
            <BirthConsent handleConsent={handleConsent} />
        )


    );
};

export default RecetasEspeciales;
