import React, {useRef, useState, useEffect} from "react";
import styles from "./carousel.module.scss"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { IsMobileDevice, CleanArrayImages } from "../../../utils/functions";
import loadable from '@loadable/component'
import 'react-tridi/dist/index.css';
const Tridi = loadable(() => import('react-tridi'))



const Carousel = ({variedad}) => {

    const [isMobile, setIsMobile] = useState()

    useEffect(()=>{
        setIsMobile(IsMobileDevice());
    },[])

    const lata = useRef()

    function rotar(){
        lata.current.play()
        console.log(lata.current)
    }



    return (

        <div >
            <div className={styles.container}>
                <BsArrowLeft className={styles.icono} onClick={()=>lata.current.prev()}/>

                <div>
                    <Tridi
                        ref={lata}
                        location={"/" + variedad.nombre}
                        format="png"
                        count={variedad.qPics}
                        autoplaySpeed={50}
                    />
                </div>
                <BsArrowRight className={styles.icono} onClick={()=>lata.current.next()}/>

            </div>
        </div>


    );
};

export default Carousel;

{/* <div className={styles.container}>
<BsArrowLeft onClick={()=>setCount(count-1)} className={styles.icono}/>
<div >
    <img src={renderSwitch(count)} alt="LataCerveza" className={styles.imagen}/> 
</div>
<BsArrowRight onClick={()=>setCount(count+1)} className={styles.icono}/>
</div> */}
