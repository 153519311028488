import React from 'react';
import styles from './call-to-action.module.scss'
import callToActionImage from '../../../../../images/pasionaria/callToActionImage.svg'
import capulloImage from '../../../../../images/pasionaria/Capullo.png'
import leafImage from '../../../../../images/pasionaria/Hoja flor.png'
const CallToAction = ({isMobile}) => {


    return <section className={isMobile ? styles.mobileContainer : styles.container}>
        <h4>
            ¿LA PROBASTE?
        </h4>
        <a href={"https://www.cervezapatagonia.com.ar/form-recetas-especiales"} target={"_blank"}>
            <button>
                <img src={callToActionImage} alt={"cta"}/>
                <span>CONTANOS QUÉ TE PARECIÓ</span>
            </button>
        </a>
        {/*<img src={capulloImage} alt={'capullo'} className={styles.capulloImage}/>*/}
        {/*<img src={leafImage} alt={'hoja'} className={styles.leafImage}/>*/}
    </section>
}

export default CallToAction;
