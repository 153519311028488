import styles from "./recetas.module.scss";

import React, { useEffect } from "react";

import subrayado from '../../../images/indira/subrayadovioleta.svg'
import Logo from '../../../images/indira/Logo.svg'

import logoRecetas from '../../../images/pasionaria/recetasEspecialesLogo.svg'

import { RecetasWrapper } from "./RecetasWrapper"
import Pasionaria from "./Pasionaria/Pasionaria"
import Details from "./Pasionaria/Details/Details"
import CallToAction from "./Pasionaria/CallToAction/CallToAction"
import lupuloImage from '../../../images/pasionaria/Lupulo.png'
import fruiteImage from '../../../images/pasionaria/Fruto4.png'
import flowerImage from '../../../images/pasionaria/Flor.png'

const Recetas = () => {


    return (

        <div className={styles.container} id='recetasespeciales'>
            <img src={lupuloImage} alt={'lupulo'} className={styles.lupuloImage}/>
            <img src={fruiteImage} alt={'fruto'} className={styles.topFruiteImage}/>
            <img src={flowerImage} alt={'flor'} className={styles.mobileFlowerImage}/>
            <div className={styles.wrapper2}>
                <img src={Logo} alt="Logo" className={styles.logo}/>
                <img src={logoRecetas} alt="logoRecetasEspeciales" className={styles.logoRecetas}/>
                <div className={styles.pContainer}>
                    <p>CERVEZAS ÚNICAS QUE ENCONTRÁS EN NUESTROS REFUGIOS</p>
                    <p>TIRADAS O EN LATA PARA DISFRUTAR DONDE QUIERAS.</p>
                </div>
            </div>
            <Pasionaria/>
            <Details/>
            {/*<CallToAction/>*/}
            {/*<RecetasWrapper/>*/}
        </div>
    );
};

export default Recetas;
